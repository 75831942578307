import React, { createContext, Context, useState } from 'react';
import { LoginType, AppContextProps, AppContextStateProps } from '../../interfaces/interfaces';

const defaultAppContext: AppContextProps = {
  loginType: undefined,
  personId: undefined,
  locationId: undefined,
  selectedPersonId: undefined,
  firstAndLastName: undefined,
};

export const AppContext: Context<AppContextStateProps> = createContext<AppContextStateProps>(defaultAppContext as AppContextStateProps);

export const AppContextProvider: React.FC = ({ children }) => {
  const [loginType, setLoginType] = useState<LoginType | undefined>(defaultAppContext.loginType);
  const [personId, setPersonId] = useState<string | undefined>(defaultAppContext.personId);
  const [locationId, setLocationId] = useState<string | undefined>(defaultAppContext.locationId);
  const [selectedPersonId, setSelectedPersonId] = useState<string | undefined>(defaultAppContext.selectedPersonId);
  const [firstAndLastName, setFirstAndLastName] = useState<string | undefined>(defaultAppContext.firstAndLastName);

  return (
    <AppContext.Provider value={{
      loginType: loginType,
      personId: personId,
      locationId: locationId,
      selectedPersonId: selectedPersonId,
      firstAndLastName: firstAndLastName,
      setLoginType,
      setPersonId,
      setLocationId,
      setSelectedPersonId,
      setFirstAndLastName,
    }}>
      {children}
    </AppContext.Provider>
  );
};