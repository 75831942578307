import {SAButton, SACard, SAInput, SARadioGroup, SAText, SAAlert, SAUXTheme} from '@saux/design-system-react';
import styled from 'styled-components';
import Select from 'react-select';

const mobileWidth = 600;

type StyleProps = {
  display?: string;
  err?: boolean;
  error?: boolean;
  theme?: SAUXTheme;
  className?: string;
};

export const ConfirmContactTypeChangesModal = styled.div`
  padding-bottom: 45px;
`;

export const ConfirmContactTypeChangesModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  button:last-child {
    display: none;

    &:focus {
      outline: auto;
    } 
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    button:last-child {
      display: flex;
    }
  }
`;

export const ConfirmContactTypeChanges = styled(SACard)`
  ${({ theme }: StyleProps) => {
  return `
      display: flex;
      flex-direction: column;
      width: 650px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
      
      header {
        height: 15px;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
}};
`;

export const ConfirmContactTypeChangesModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConfirmContactTypeChangesModalFooter: any = styled.div`
  ${({ theme }: StyleProps) => {
  return `
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 60px;

      button {
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          font-weight: ${theme?.font.primary.weight.normal};
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
        height: 100%;
      }
    `;
}};
`;

export const ContactAgencyOperationsModalEmail = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &:first-child {
      font-weight: 700;
    }
  }
`;

export const ConfirmContactTypeChangesModalText = styled(SAText)`
  font-size: 20px;
  line-height: 26px;
`;

export const AddContactTypeContainer = styled(SACard)`
  ${({theme}: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      max-width: 700px;
      margin: auto;
      
      section {
        padding-top: 0px;
        padding-bottom: 30px;
        padding-left: 0px;
        padding-right: 0px;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        margin: 0;
        max-width: 100%;
        width: 100%;
      }
    `;
}};
`;

export const Container = styled.div`
  ${({theme}: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;

      .textLikeInput {
        font-size: 16px;
      }

      .textLikeInputWrapper {
        label {
          z-index: 0;
        }

        legend {
          span {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      .saInputLabel {
        font-weight: ${theme?.font.primary.weight.normal};
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        margin: 0;
        max-width: 100%;
        width: 100%;
      }

      fieldset {
        z-index: 0;
      }
    `;
  }};
`;

export const StyledSAButton = styled(SAButton)`
  display: flex;
`;

export const StyledSAAddContactTypeButton = styled(SAButton)`
  display: flex;
  color: rgba(65, 85, 32, 1);
`;

export const StyledIcons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Header = styled(Container)`
  ${({theme}: StyleProps) => {
    return `
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding-top: 20px;
        color: ${theme?.colors.accentBlue};
        @media only screen and (max-width: ${mobileWidth}px) {
          :first-child {
            padding-bottom: 20px;
          }
      }
   `;
  }};
`;

export const AddressLine = styled.div`
  padding-bottom: 40px;
`;

export const StyledHeader = styled(SAText)`
  ${({theme}: StyleProps) => {
    return `
            color: ${theme?.colors.primary};
        `;
  }};
`;

export const StyledHeaderLine = styled(Container)`
  ${({theme}: StyleProps) => {
    return `
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-top: 30px;
        color: ${theme?.colors.accentBlue};
        @media only screen and (max-width: ${mobileWidth}px) {
        margin: 0;
      }
   `;
  }};
`;

export const StyledHeaderLine2 = styled(SAText)`
  display: flex;
  justify-content: flex-start;
  padding: 5px 0px 5px 0px;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
  @media only screen and (max-width: ${mobileWidth}px) {
    flex-direction: column;
    padding-top: 0px;
  }
`;

export const StyledRadioGroup = styled(SARadioGroup)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
  @media only screen and (max-width: ${mobileWidth}px) {
    flex-direction: row;
  }
`;

export const Content = styled(Container)`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
    padding: 10px 0px 10px 0px;
  }
`;

export const StyleDiv = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(243 245 248);
  padding: 10px 10px;
  border: 1px solid rgb(243 245 248);
  width: 30%;
  height: 35%;
  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
    padding: 10px 0px 10px 0px;
  }
`;

export const ContentEdit = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 48%;
  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
    padding: 10px 0px 10px 0px;
  }
`;

export const StyledInput = styled(SAInput)`
  ${({error, theme}: StyleProps) => {
    const color = error ? `${theme?.color.alert.background}` : 'initial';
    return `
        .textLikeInput input {
            font-size: 16px;
            font-weight: normal;
            color: #3b3b3b;
        }
      div {
        span {
          color: ${color};
        }
      }
      #county {
        display: none;
      }
      #county.textLikeInput { display: none; }
    `;
  }};
`;

export const HiddenStyledInput = styled(SAInput)`
  display: none;
`;

export const ContentEditLine2 = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 22%;
  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
    padding: 10px 0px 10px 0px;
    :first-child {
      padding-top: 0px;
    }
  }
`;

export const FooterButtons = styled(Container)`
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 40px;
  padding-bottom: 40px;
  width: calc(100% - 20px);
  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

export const AddContactTypeFooterButtons = styled(Container)`
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 30px;
  padding-right: 10px;
  width: calc(100% - 20px);

  button {
    &:first-child {
      font-weight: 400;
    }
    
    &:last-child {
      margin-left: 15px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

export const ExistingContactTypes = styled.div`
  display: flex;
  padding-top: 10px;
  padding-bottom: 60px;
  flex-direction: row;
  width: calc(100% - 20px);
`;

export const ExistingContactType = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-top: 1px solid #ffffff;
  margin-left: 30px;
  
  span:first-child {
    color: #979797;
  }
  span:last-child {
    color: hsl(0,0%,23%);;
  }
  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
    padding: 10px 0px 10px 0px;
  }
`;

export const PersonalLineQuestion = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-top: 1px solid #ffffff;
  margin-left: 30px;
  margin-right: 30px;
  gap: 4px;
`;

export const PersonalLineAlert = styled(SAAlert)`
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const CardValidation = styled.div`
  ${({ theme }: StyleProps) => {
  return `
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      background-color: #FDEAE7;
      padding: ${theme?.size.spacer.medium};
      margin-bottom: 5px;

      p {
        color: ${theme?.color.alert.background};
        font-weight: ${theme?.font.primary.weight.bold};
      }
    `;
}};
`;

export const StyledCancelButton = styled(SAButton)`
  ${({theme}: StyleProps) => {
    return `
        font-weight: ${theme?.font.primary.weight.normal};
        margin-right: ${theme?.size.spacer.medium};
     `;
  }};
`;

export const StyledText = styled(SAText)`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
    padding: 10px 0px 10px 0px;
  }
`;

export const PrivilegesStyledText = styled(SAText)`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
    padding: 5px 0px 10px 0px;
  }
`;

export const StyledHeaderText = styled(SAText)`
  display: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 0 10px 0;

  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
    padding: 5px 0px 10px 0px;
  }
`;

export const HelpTextIconButton = styled(SAButton)`
  padding: 0;

  &:hover {
    background-color: transparent;
  }
`;

export const PrivilegesSectionWrapper = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-height: 360px;
    
      @media only screen and (max-width: ${mobileWidth}px) {
        flex-direction: column;
        flex-wrap: nowrap;
        max-height: fit-content;
      }
    
      div {
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 33%;

        &.helpicon {
          padding-left: 10px;
          flex-basis: 0;

          button {
            padding: 0;
            display: flex;
            align-items: center;

            &:hover {
              background-color: transparent;
            }
          }

          svg {
            g {
              path {
                fill: hsl(205, 99%, 39%);
              }
            }
          }
        }
      }

      .saCheckboxWrapper {
        display: flex;
        flex-grow: 0;
        align-items: center;
        margin: 10px 0;
        height: 40px;
        padding: 0 15px;
        z-index: 0;

        &:hover {
          background-color: rgba(216,223,236,0.25);
          cursor: pointer;

          @media only screen and (max-width: ${mobileWidth}px) {
            background-color: initial;
          }
        }

        input {
          &:hover {
            cursor: pointer;
          }
        }

        label {
          &:hover {
            cursor: pointer;
          }
        }
      }
    `;
  }};
`;

export const PrivilegesViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 20px;
  padding-right: 50px;
  @media only screen and (max-width: ${mobileWidth}px) {
    flex-direction: column;
    padding-top: 0px;
  }
`;

export const PrivilegesContent = styled(Container)`
  display: flex;
  flex-direction: row;
  padding-right: 72px;

  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
    padding: 10px 0px 10px 0px;
  }
`;

export const StyledError = styled.span`
  ${({theme}: StyleProps) => {
    return `
        color: ${theme?.color.alert.background};
        display: block;
        font-size: 12px;
        padding: 5px 0 0 15px;
    `;
  }};
`;

export const List = styled.ul`
  margin-left: 25px;
`;

export const SARemoveModalBody = styled(SACard)`
  display: none;
  background-color: #ffffff;
  z-index: 1000;
  h2 {
    font-size: 24px;
    margin-top: 20px;
  }
  @media screen and (min-width: ${mobileWidth}px) {
    display: block;
    width: 675px;
    min-height: 300px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  @media screen and (max-width: ${mobileWidth}px) {
    display: block;
    width: 100%;
    top: 50%;
    left: 50%;
  }
`;

export const RemovelModalText = styled(SAText)`
  font-size: 16px;
  color: rgba(102, 102, 102, 1);
`;

export const RemovelModalUpperText = styled(SAText)`
  font-size: 20px;
  padding-right: 5px;
  flex-direction: column;
  color: rgba(59, 59, 59, 1);
`;

export const RemovelModalUpperTextBold = styled(SAText)`
  font-size: 20px;
  font-weight: bold;
  color: rgba(59, 59, 59, 1);
`;

export const SAModalBody = styled(SACard)`
  display: none;
  background-color: #ffffff;
  padding-bottom: 30px;
  z-index: 1000;
  @media screen and (min-width: ${mobileWidth}px) {
    display: block;
    width: 40%;
    height: 209px;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  @media screen and (max-width: ${mobileWidth}px) {
    display: block;
    width: 100%;
    height: 209px;
    top: 50%;
    left: 50%;
  }
`;

export const StyledModalText = styled.div`
  font-size: 14px;
  padding-top: 15px;
  @media screen and (max-width: ${mobileWidth}px) {
    padding-left: 15px;
  }
`;

export const StyledRemoveContactModalText = styled.div`
  font-size: 14px;
  padding-top: 5px;
  @media screen and (max-width: ${mobileWidth}px) {
    padding-left: 15px;
  }
`;

export const StyledRemoveModalTextDescription = styled.div`
  display: flex;
  font-size: 14px;
  padding-top: 15px;
  flex-direction: row;
  @media screen and (max-width: ${mobileWidth}px) {
    padding-left: 15px;
  }
`;

export const StyledRemoveModalText = styled.div`
  padding-top: 30px;
  flex-direction: column;
  @media screen and (max-width: ${mobileWidth}px) {
    padding-left: 15px;
  }
`;

export const ModalBody = styled.div`
  padding: 30% 30px 30px 30px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-top: 20px;
  
  .sa-button:first-child {
    font-weight: 400;
    margin-right: 20px;
  }
`;

export const RemoveModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-top: 40px;
  
  .sa-button:first-child {
    font-weight: 400;
    margin-right: 20px;
  }
`;

export const RemoveModalCanNotRemoveButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  position: absolute;
  bottom: 30px;
  right: 30px;
`;

// React Select Styles
export const StyledSelect = styled(Select)`
  font-size: 16px;
  font-weight: normal;
  color: #041e41;

  .Select__control {
    background-color: #fff;
    border-radius: 4px;
    border: ${(props: any) => props.error ? "1px solid #cf021a" : "1px solid #ccc"};
    color: #333;
    cursor: default;
    border-spacing: 0;
    border-collapse: separate;
    outline: none;
    overflow: hidden;
    position: relative;
    width: 100%;

    :hover {
      border: ${(props: any) => props.error ? "1px solid #cf021a" : "1px solid #ccc"};
    }

    &.Select__control--is-focused {
      border: ${(props: any) => props.error ? "2px solid #cf021a" : "2px solid #0174C6"};
      box-shadow: none;
    }
  }
`;

export const StyledDiv = styled.div`
  align-items: center;
  position: relative;
  padding-top: 5px;
`;

export const styles = {
  // this displays as unused but it really is!
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "#65A100"
  }),
  menuList: (base: any, state: any) => ({
    ...base,
    height: "auto",
    border: "1px solid #0173c6",
    color: "#015DA0",
    borderRadius: '4px',

    "::-webkit-scrollbar": {
      width: "10px",
      height: '92px',
      border: 'none',
    },
    "::-webkit-scrollbar-track": {
      background: "white",
      borderRadius: '10px',
    },
    "::-webkit-scrollbar-thumb": {
      background: "#0173C6",
      borderRadius: '10px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)',
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555"
    }
  }),
  control: (base: any) => ({
    ...base,
    backgroundColor: 'white',
    color: 'white',
    height: '50px',
    borderRadius: '4px',
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: 'none'
  }),
}

export const StyledCheckboxLabel = styled.label`
  cursor: pointer;
`;

export const StyledLabel: any = styled.div.attrs(({err, className}: StyleProps) => ({
  err,
  className,
}))`
  ${({err, theme, className}: StyleProps) => {
    let color = '';

    if (className === 'focused') {
      color = err ? `${theme?.color.alert.background}` : `${theme?.colors.primary}`;
    } else {
      color = err ? `${theme?.color.alert.background}` : '#666666';
    }

    return `
      color: ${color};
      background-color: white;
      font-size: 12px;
      font-weight: ${theme?.font.primary.weight.normal};
      left: 0;
      padding-top: inherit;
      padding-left: 5px;
      padding-right: 5px;
      position: absolute;
      top: 0;
      transform: translate(calc(15px - 5px), -8px);
      visibility: visible;

      &.focused {
        color: ${color};
      }
    `;
  }};
`;

export const ContactTypeSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  width: 100%;
  border: none;

  @media only screen and (max-width: ${mobileWidth}px) {
    flex-direction: column;
    padding-top: 0px;
  }
`;
export const StyledSACard = styled(SACard)`
  display: flex;
  width: 100%;
  box-shadow: none;
  padding-bottom: 30px;
  @media only screen and (max-width: ${mobileWidth}px) {
    flex-direction: column;
    padding-top: 0px;
  }
`;

export const AgencyLocationPanel = styled.div`
  padding: 0px 0px 10px 20px;
`;

export const ContactTypeAccordingViewText = styled(SAText)`
  font-size: 14px;
  padding-bottom: 10px;
`;
export const AgencyLocationWrapper = styled.div`
  padding-top: 10px;
`;
export const AgencyLocations = styled(SAText)`
  ${({theme}: StyleProps) => {
    return `
        font-size: ${theme?.font.primary.size.mediumTEMP};
        font-weight: ${theme?.font.primary.weight.normal};
        
        @media only screen and (max-width: ${mobileWidth}px) {
          :first-child {
            padding-bottom: 20px;
          }
      }
   `;
  }};
`;

export const ProfileSpinnerContainer = styled.div.attrs(({display}: StyleProps) => ({
  display,
}))`
  ${({display, theme}: StyleProps) => {
    const disp = display === 'display' ? `` : `display: none;`;

    return `
      ${disp}
      margin-left: 10px;
      svg:last-child {
        margin-left: 0;
      }
    `;
  }};
`;

export const DisabledContinueButton = styled(SAButton)`
  ${({theme}: StyleProps) => {
    return `
      color: ${theme?.colors.white};
      background-color: ${theme?.colors.green};

      &:hover {
        background-color: ${theme?.colors.green};
      }
    `;
  }};
`;

// Learn More

export const LearnMoreContainer = styled(SACard)`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
  padding: 15px 10px 30px 20px;

  @media only screen and (max-width: ${mobileWidth}px) {
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
`;

export const StyledLearnMoreText = styled(SAText)`
  display: flex;
  flex-direction: column;
  padding: 10px 0px 10px 0px;

  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
    padding: 10px 0px 10px 0px;
  }
`;
export const StyledLearnMoreTitleText = styled(SAText)`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  cursor: pointer;
  @media only screen and (max-width: ${mobileWidth}px) {
    width: 100%;
    padding: 10px 0px 10px 0px;
  }
`;

export const LearnMoreLineSpacing = styled.div`
  padding-top: 20px;

  hr {
    border: 1px solid #D9DFEB;
  }
`;

export const LearnMoreContentContainer = styled.div`
  padding-right: 20px;
`;

export const LearnMoreHeader = styled.div`
  ${({theme}: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      padding-bottom: 20px;
      padding-top: 10px;
      position: static;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
        font-size: ${theme?.font.primary.size.medium};
      }

      span:first-child {
        padding-right: 10px;
      }
      
      @media only screen and (max-width: ${mobileWidth}px) {
        height: 50px;
      }
    `;
  }};
`;

export const StyledAdContactTypeCheckbox = styled.div`
  padding-bottom: 20px;
  label {
    padding-right: 10px;
  }
`;

export const ContactTypes = styled(SACard)`
  ${({theme}: StyleProps) => {
    return `
          display: flex;
          flex-direction: column;
          width: 700px;
          overflow: auto;
          margin: auto;
          border: none;
          border-radius: 0px;
          box-shadow: none;
          border-bottom: 1px solid #ffffff;
          z-index: -1;
          padding-bottom: 15px;
        
          section {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            border-bottom: 1px solid #ffffff;
          }
          header {
            position: static;
            margin-bottom: 40px;
             h2 {
              font-size: 23px;
            }
            button {
              font-size: 16px;
            }
          }
        
          @media only screen and (max-width: ${mobileWidth}px) {
            flex-grow: 1;
            width: auto;
            width: 100%;
            height: 100%;
            border-radius: 0;
        
            section {
              padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
            }
          }
        `;
  }};
`;

export const LearnMoreDiv = styled.div`
  ${({theme}: StyleProps) => {
    return `
          display: flex;
          flex-direction: column;
          overflow: auto;
          border: none;
          border-radius: 0px;
          box-shadow: none;
          z-index: -1;
          background-color: white;
          max-height: 600px;
        
          section {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
          }
          header {
            position: static;
          }
        
           &::-webkit-scrollbar {
            width: 7px;
            height: 92px;
            border: none;
          }
        
          &::-webkit-scrollbar-track {
            background: rgb(243 245 248);
            border-radius: 7px;
          }
        
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #8A8A8A;
            box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
          }
        
          @media only screen and (max-width: ${mobileWidth}px) {
            flex-grow: 1;
            width: auto;
            width: 100%;
            height: 100%;
            border-radius: 0;
        
            section {
              padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
            }
          }
        `;
  }};
`;

export const LearnMoreText = styled(SAText)`
  cursor: pointer !important;
`;

export const Popover = styled.div`
  ${({ theme }: StyleProps) => {
  return `
      display: flex;
      flex-direction: column;
      color: hsla(220, 38%, 97%, 1);
      font-size: ${theme?.font.primary.size.smallTEMP};
      font-weight: ${theme?.font.primary.weight.normal};
      line-height: 16px;
      background-color: hsla(213, 30%, 35%, 1);
      border: 1px solid hsla(213, 30%, 35%, 1);
      box-sizing: border-box;
      box-shadow: 0px 0px 5px rgba(0, 32, 56, 0.22);
      border-radius: 4px;
      padding: 15px;
      width: 360px;
    `;
}};
`;

export const HelpContainer = styled.div`
  display: flex;
  flex-directino: column;
`;

export const TextArea = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      margin: 20px 0;

      label {
        background-color: ${theme?.colors.white};
        color: #666666;
        font-size: 12px;
        font-weight: ${theme?.font.primary.weight.normal};
        position: relative;
        top: 8px;
        left: 8px;
        padding-right: 5px;
        padding-left: 5px;

        &.focused {
          color: ${theme?.colors.blue};
        }
      }

      textarea {
        resize: none;
        width: 100%;
        height: 60px;
        padding: 10px;
        font-family: ${theme?.font.primary.family};
        font-size: ${theme?.font.primary.size.medium};
        font-weight: ${theme?.font.primary.weight.normal};
        border-radius: 4px;
        border: 1px solid #DEDEDE;

        &:focus {
          outline: 0;
          border: 2px solid ${theme?.colors.blue};
        }
      }
    `;
  }};
`;
