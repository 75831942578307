import React, { useState, useEffect } from "react";
import {
  SACard,
  SAButton,
  SAIcon,
  SAIcons,
  SAIconSize,
  SAModal,
  SAText,
  SAUXTheme,
  SASpinner,
  SAUX360Theme
} from '@saux/design-system-react';
import { AppGenPayloadAdd, NewContact } from '../../../interfaces/interfaces';
import { encrypt, decrypt } from '../../../utils/crypto';
import styled from 'styled-components';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../../../graphql/mutations';
import * as queries from '../../../graphql/queries';
import { PostPersonRequestedQuery, SendAddProducerEmailMutation } from "../../../API";
import handleGraphQLErrors from "../../../utils/handleGraphQLErrors";
import { checkAuthStatus } from "../../../utils/utils";
import checkIfLicenseProducerRequested from "../../../utils/checkIfLicenseProducerRequested";
import { simpleGTMDataLayer } from "../../../utils/GTMHelpers";

const mobileWidth = 600;

type ApplicationGeneratedProps = {
  workflow: 'add contact' | 'profile';
  type: 'add' | 'remove' | undefined;
  payload: AppGenPayloadAdd | null;
  onClickCancel: Function;
  onClickSendEmail: Function;
  onEmailSent: Function;
  onClickClose: Function;
  displaySpinner: boolean;
  showLPAlreadyRequested?: Function;
};

type StyleProps = {
  error?: boolean;
  theme?: SAUXTheme;
};

const Modal = styled(SAModal)`
  display: flex;
  top: 50%;
  left: 50%;
  justify-content: center;
  align-items: center;
  overflow: auto;

  &.hidemodal {
    display: none;
  }
`;

const ApplicationGenerated = styled(SACard)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      width: 600px;
      overflow: auto;
      margin: auto;

      section {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        flex-grow: 1;
        width: auto;
        width: 100%;
        height: 100%;
        border-radius: 0;

        section {
          padding: ${theme?.size.spacer.large} ${theme?.size.spacer.medium};
        }
      }
    `;
  }};
`;

const ModalHeader = styled.div`
  padding-bottom: 45px;
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;

  button:last-child {
    display: none;

    &:focus {
      outline: auto;
    } 
  }

  @media only screen and (max-width: ${mobileWidth}px) {
    button:last-child {
      display: flex;
    }
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalFooter: any = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: 60px;

      button {
        font-weight: ${theme?.font.primary.weight.normal};
        margin-left: ${theme?.size.spacer.medium};

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          font-weight: ${theme?.font.primary.weight.bold};
        }
      }

      @media only screen and (max-width: ${mobileWidth}px) {
        justify-content: center;
        height: 100%;
      }
    `;
  }};
`;

const Panel = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      background-color: #F2FAE5;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1;
      margin: 0px -30px -30px -30px;
      padding: 30px 30px 30px 30px;
    `;
  }};
`;

const MessageContainer = styled.div`
  margin-top: 30px;
`;

const Email = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;

  span {
    font-size: 14px;
    font-weight: 500;

    &:first-child {
      font-weight: 700;
    }
  }
`;

const EmailSent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Buttons = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: row;

      button[type='button'] {
        font-weight: ${theme?.font.primary.weight.normal};
      }
    `;
  }};
`;

const DashboardIcon = styled.svg`
  ${({ theme }: StyleProps) => {
    return `
      margin-right: ${theme?.size.spacer.medium};
    `;
  }};
`;

const SpinnerContainer = styled.div`
  margin-left: 10px;
  svg:last-child {
    margin-left: 0;
  }
`;

const DisabledSendEmailButton = styled(SAButton)`
  ${({ theme }: StyleProps) => {
    return `
      color: ${theme?.colors.white};
      background-color: ${theme?.colors.green};

      &:hover {
        background-color: ${theme?.colors.green};
      }
    `;
  }};
`;

export default function ModalApplicationGenerated({ workflow, type, payload, onClickCancel, onClickSendEmail, showLPAlreadyRequested, onEmailSent, onClickClose, displaySpinner }: ApplicationGeneratedProps) {
  const [contact, setContact] = useState<NewContact>(JSON.parse(decrypt(localStorage.getItem('NewContact'))));
  const [displaySendEmail, setDisplaySendEmail] = useState<boolean>(true);
  const [displayClose, setDisplayClose] = useState<boolean>(false);
  const email = workflow === 'add contact' && contact ? contact.contactDetails.email : decrypt(localStorage.getItem('selectedEmail'));
  const successIcon = getSuccessIcon('40px', 'hsl(82,100%,32%)');
  const isEmployee: boolean = decrypt(localStorage.getItem('loginType')) === 'employeeID' ? true : false;
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const contactName = workflow === 'add contact' && contact
    ? `${contact.contactDetails.generalDetails.firstName} ${contact.contactDetails.generalDetails.lastName}` 
    : `${decrypt(localStorage.getItem('selectedFirstName'))} ${decrypt(localStorage.getItem('selectedLastName'))}`;
  const adminFirstName = decrypt(localStorage.getItem('loggedInUserFirstName'));
  const adminLastName = decrypt(localStorage.getItem('loggedInUserLastName'));
  const personId = decrypt(localStorage.getItem('selectedPersonId'));
  const loggedInPersonId = decrypt(localStorage.getItem('personId'));

  // Spinner
  const [showSpinner, setShowSpinner] = useState<boolean>(displaySpinner);

  const spinner = (
    <SpinnerContainer>
      <SASpinner variant="circular-continuous" delay={0} color={SAUX360Theme.colors.blueGray600} size="20px" />
    </SpinnerContainer>
  );

  function handleClickApplicationGeneratedModal(event: React.MouseEvent) {
    event.stopPropagation();

    if (showSpinner) {
      return;
    }
    // only allow closing of modal via the background while "Send Email" button is displayed.
    if (displaySendEmail) {
      // get the card element inside the modal
      const cardElement = document.querySelector('#modal > div > article > section');

      // close modal when clicking outside of the card element
      if (!(cardElement instanceof Element && cardElement.contains(event.target as Node))) {
        onClickCancel(event);
      }
    }    
  }

  function handleClickCancel(event: React.MouseEvent) {
    event.stopPropagation();
    onClickCancel(event);
  }

  async function handleClickSendEmail(event: React.MouseEvent) {
    if (!isEmployee) {
      event.stopPropagation();
      onClickSendEmail();

      const isAuthenticated: boolean = await checkAuthStatus();
      if (!isAuthenticated) {
        return;
      }

      const data = await checkIfLicenseProducerRequested({
        adminName: `${adminFirstName} ${adminLastName}`,
        adminPersonId: loggedInPersonId,
        selectedPersonId: workflow === "profile" ? personId : email,
      });
      if (data) {
        showLPAlreadyRequested && showLPAlreadyRequested({ adminName: data.admin_name, requestedOn: data.created });
        return;
      }
      
      const response = await(
        API.graphql(
          graphqlOperation(mutations.sendAddProducerEmail, {
            body: payload,
          }),
          {
            Authorization: `Bearer ${decrypt(localStorage.getItem("auth_accesstoken"))}`,
          }
        ) as Promise<{ data: SendAddProducerEmailMutation }>
      )
        .then((res) => {
          if (res.data) {
            return res.data.SendAddProducerEmail;
          } else {
            handleGraphQLErrors(res);
          }
        })
        .then((res) => {
          if (res?.statusCode === 200) {
            return res.body as { message: string } | null | undefined;
          } else {
            return null;
          }
        })
        .then((res) => {
          if (res) {
            if (res.message === "Onboarding request for licensed producer mail has been delivered successfully") {
              return "Sent Successfully";
            } else {
              throw new Error("Something went wrong - 39");
            }
          } else {
            throw new Error("Something went wrong - 40");
          }
        })
        .catch((err) => {
          if (err?.message) {
            console.error(err.message, err);
          } else {
            handleGraphQLErrors(err);
          }
          window.location.href = "error";
        });

      if (response) {
        await(
          API.graphql(
            graphqlOperation(queries.postPersonRequested, {
              admin_name: `${adminFirstName} ${adminLastName}`,
              person_id: workflow === "profile" ? personId : email,
              admin_person_id: loggedInPersonId,
            }),
            {
              Authorization: `Bearer ${decrypt(localStorage.getItem("auth_accesstoken"))}`,
            }
          ) as Promise<{ data: PostPersonRequestedQuery }>
        )
          .then((res) => {
            if (res.data) {
              return res.data.PostPersonRequested;
            } else {
              handleGraphQLErrors(res);
            }
          })
          .then((res) => {
            if (res) {
              if (res.statusCode === 200 && res.body?.updated === "True") {
                setEmailSent(true);
                onEmailSent();
              } else {
                throw new Error("Something went wrong - 49");
              }
            } else {
              throw new Error("Something went wrong - 50");
            }
          })
          .catch((err) => {
            if (err?.message) {
              console.error(err.message, err);
            } else {
              handleGraphQLErrors(err);
            }
            window.location.href = "error";
          });
      } else if (response) {
        setEmailSent(true);
        onEmailSent();
      }

      simpleGTMDataLayer({
        event: 'addLicensedProducerContactEvent',
        event_action: 'Send Email',
        event_category: window.location.pathname,
        event_label: 'Add Contact: Licensed Producer'
      });
    }
  }

  function handleClickClose(event: React.MouseEvent) {
    event.stopPropagation();
    onClickClose(event);
  }
  
  function getSuccessIcon(size: string, color: string) {
    const renderSize = size;
    return (
      <DashboardIcon
        width={renderSize}
        height={renderSize}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="40" height="40" rx="20" fill={color} />
        <path
          d="M10 20C10 20 16.318 27 16.8901 27C17.4621 27 30 13 30 13" stroke="white" strokeWidth="4" strokeLinecap="round"
        />
      </DashboardIcon>
    );
  }

  useEffect(() => {
    function focusDefault() {
      const modal = document.querySelector('#modalapplicationgenerated') as HTMLElement;
      if (modal) {
        const element = modal.querySelector('button.sa-button') as HTMLElement;
        if (element) {
          element.focus();
        }
      } 
    }

    function focusModalApplicationGenerated(e: any) {
      const modal = document.querySelector('#modalapplicationgenerated') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;
      if (modal && root) {
        if ((e.type === 'focusout' && root.contains(e.relatedTarget)) || (e.type === 'focusin' && root.contains(e.target))) {
          let element = null;
          if (document.documentElement.clientWidth <= mobileWidth) {
            element = modal.querySelector('button') as HTMLElement;
          } else {
            element = modal.querySelector('button.sa-button') as HTMLElement;
          }
          if (element) {  
            element.focus();
          }
        }
      }
    }

    focusDefault();
    document.addEventListener('focusin', focusModalApplicationGenerated);
    document.addEventListener('focusout', focusModalApplicationGenerated);

    return () => {
      document.removeEventListener('focusin', focusModalApplicationGenerated);
      document.removeEventListener('focusout', focusModalApplicationGenerated);
    }
  }, []);

  useEffect(() => {
    setShowSpinner(displaySpinner);

    if (emailSent && !displaySpinner) {
      setDisplaySendEmail(false);
      setDisplayClose(true);
    }
  }, [emailSent, displaySpinner]);

  return (
    <Modal id="modalapplicationgenerated" onClickHandler={handleClickApplicationGeneratedModal}>
      <ApplicationGenerated variant="minimal">
        <ModalHeader>
          <ModalTitle>
            <SAText type="heading-2" text="Application Generated" />
            <SAIcon className="closemodal" icon={SAIcons.x} size={SAIconSize.medium} colorVariant="dark" onClick={handleClickCancel} />
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          {type === 'remove' &&
            <SAText type="standard" text="An email will be sent to Agency Operations to request the removal of the contact type Licensed Producer." />
          }
          {type === 'add' &&
            <>
              <SAText type="standard" text="An email will be sent to the address below with the licensed producer application." />
              <Email>
                <SAText type="standard" text="Email Address" />
                <SAText type="standard" text={email.toLowerCase()} />
              </Email>
              <MessageContainer>
                <SAText type="standard" text={`${contactName} will receive an email for system access after the Licensed Producer application and onboarding process is complete. Once ${contactName} has submitted the application the onboarding process will only take a few days.`}></SAText>
              </MessageContainer>
            </>
          }
        </ModalBody>
        <ModalFooter>
          {displaySendEmail &&
            <>
              <SAButton disabled={showSpinner} fullWidthUnder={mobileWidth} label="Cancel" onClick={handleClickCancel} variant="link-large" color="text" textTransform="uppercase" />
              {!showSpinner &&
                <SAButton fullWidthUnder={mobileWidth} label="Send Email" onClick={handleClickSendEmail} variant="primary-large" textTransform="uppercase" disabled={isEmployee} />
              }
              {showSpinner &&
                <DisabledSendEmailButton endIcon={spinner} disabled fullWidthUnder={mobileWidth} label="Send Email" variant="primary-large" textTransform="uppercase" />
              }
            </>
          }
          {!displaySendEmail &&
            <Panel>
              <EmailSent>
                {displayClose &&
                  <>
                    {successIcon}
                    <SAText type="standard" text="Email Sent" />
                  </>
                }
              </EmailSent>
              <Buttons>
                {displayClose &&
                  <SAButton label="Close" onClick={handleClickClose} variant="link-large" color="text" textTransform="uppercase" />
                }
              </Buttons>
            </Panel>
          }
        </ModalFooter>
      </ApplicationGenerated>
    </Modal>
  );
}
