import React, { useState, useRef, useMemo, useEffect, forwardRef } from "react";
import { SAButton, SACheckbox, SASearch, SAText, SAUXTheme } from "@saux/design-system-react";
import styled from "styled-components";
import { TableVirtuoso, TableComponents, VirtuosoHandle } from "react-virtuoso";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Constants,
  ContactTypeValidation,
  Person,
  TerminateContactsValidation,
} from "../../interfaces/interfaces";

type TerminateContactsTableProps = {
  onChange: (values: string[]) => void;
  values: string[];
  personsData: Person[];
  personsWithError?: Map<string, TerminateContactsValidation>;
  resetSearchFilter?: boolean;
};

type StyleProps = {
  theme?: SAUXTheme;
  constants?: Constants;
  isEmployee?: boolean;
  $error?: boolean;
};

type ErrorFilter = "SHOW_ALL" | "SHOW_ERROR_ONLY" | "NONE";

const mobileWidth = 600;

const TerminateTableContainer = styled.div`
  ${({ theme }: StyleProps) => {
    return `
    height: 600px;
    border: ${theme?.separator.line.thickness} solid ${theme?.separator.line.color};
  `;
  }};
`;

const SATableContainer = styled(TableContainer)`
  ${({ theme }: StyleProps) => {
    return `
    overflow: auto;
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      border: none;
    }
  
    &::-webkit-scrollbar-track {
        background: #F0EFEF;
        border-radius: 7px;
    }
  
    &::-webkit-scrollbar-thumb {
        background: #8A8A8A;
        border-radius: 7px;
        box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
  `;
  }};
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  @media only screen and (max-width: ${mobileWidth}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Search = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;

      .saInputWrapper {
        margin: 0 0 ${theme?.size.spacer.medium} 0;
      }

      .textLikeInput {
        padding-top: 0;
        width: initial;
        font-size: ${theme?.font.primary.size.medium};
      }

      ul {
        align-items: center;
        span {
          button {
            margin-bottom: 0;
          }
        }
        button {
          margin-bottom: 5px;
        }
      }
    `;
  }};
`;

const Checkbox = styled(SACheckbox)`
  z-index: 0;
`;

const SATableHead = styled(TableHead)`
  ${({ theme }: StyleProps) => {
    return `
    background-color: ${theme?.color.background.background};
    border-radius: ${theme?.size.radius.small};
    z-index: 1 !important;

    th {
      font-weight: 700;
      color: hsl(0,0%,23%);
    }

    @media only screen and (max-width: ${mobileWidth}px) {
      display: none !important;
    }
  `;
  }};
`;

const SATableCell = styled(TableCell)<StyleProps>`
  ${({ theme, $error }: StyleProps) => {
    const color = $error ? theme?.color.alert.background : theme?.color.container.foreground;
    return `
    color: ${color} !important;
    font-family: unset !important; 
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 0 !important;
    @media only screen and (max-width: ${mobileWidth}px) {
      display: block !important;
    }
  `;
  }};
`;

const SubTableCell = styled(TableCell)<StyleProps>`
  ${({ theme, $error }: StyleProps) => {
    const color = $error ? theme?.color.alert.background : theme?.color.container.foreground;
    const paddingTop = $error ? 11 : 6;
    return `
    color: ${color} !important;
    font-family: unset !important; 
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 0 !important;
    border-bottom: none !important;
    padding-top: ${paddingTop}px !important;
    @media only screen and (max-width: ${mobileWidth}px) {
      display: block !important;
    }
  `;
  }};
`;

const CheckBoxTableCell = styled(TableCell)<StyleProps>`
  ${({ theme, $error }: StyleProps) => {
    const color = $error ? theme?.color.alert.background : theme?.color.container.foreground;
    return `
    color: ${color} !important;
    font-family: unset !important; 
    padding: 0;
    vertical-align: top !important;
    @media only screen and (max-width: ${mobileWidth}px) {
      border: 0;
      border-top: 1px solid rgba(224, 224, 224, 1);
    }
  `;
  }};
`;

const MainTableCell = styled(TableCell)<StyleProps>`
  ${({ theme, $error }: StyleProps) => {
    const color = $error ? theme?.color.alert.background : theme?.color.container.foreground;
    return `
    color: ${color} !important;
    font-family: unset !important; 
    padding: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;

    @media only screen and (max-width: ${mobileWidth}px) {
      border: 0;
      border-top: 1px solid rgba(224, 224, 224, 1);
    }
  `;
  }};
`;

const CardValidation = styled.div`
  ${({ theme }: StyleProps) => {
    return `
      max-height: 180px;
      display: flex;
      flex-direction: column;
      gap: 22px;
      background-color: #FDEAE7;
      padding: ${theme?.size.spacer.medium};
      overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      border: none;
    }
  
    &::-webkit-scrollbar-track {
        background: #F0EFEF;
        border-radius: 7px;
    }
  
    &::-webkit-scrollbar-thumb {
        background: #8A8A8A;
        border-radius: 7px;
        box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
    `;
  }};
`;

const SelectErrorButton = styled(SAButton)`
  ${({ theme }: StyleProps) => {
    return `
      display: flex;
      flex-shrink: 0;
      margin: 0 0 ${theme?.size.spacer.medium} ${theme?.size.spacer.medium};

      @media only screen and (max-width: ${mobileWidth}px) {
        margin: 0 0 ${theme?.size.spacer.medium} 0;
      }
    `;
  }};
`;

const Email = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &:first-child {
      font-weight: 700;
    }
  }
`;

const RequiredContactTypes = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &:first-child {
      font-weight: 700;
    }
  }
`;

const ContactsTableComponents: TableComponents<Person> = {
  Scroller: forwardRef<HTMLDivElement>((props, ref) => <SATableContainer {...props} ref={ref} />),
  Table: (props: any) => <Table size="small" {...props} />,
  TableHead: SATableHead,
  TableRow: ({ item: _item, ...props }) => (
    <>
      <TableRow
        hover
        onClick={(event) => {
          event.stopPropagation();
          const element = document.getElementById(`table_checkbox_person_${props["data-index"]}`);
          if (element) {
            element.click();
            element.focus();
          }
        }}
        {...props}
      />
    </>
  ),
  TableBody: forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      <SATableCell variant="head"></SATableCell>
      <MainTableCell colSpan={3}>
        <Table style={{ tableLayout: "fixed" }} size="small">
          <TableHead>
            <TableRow>
              <SubTableCell variant="head">Name</SubTableCell>
              <SubTableCell variant="head">Login ID</SubTableCell>
              <SubTableCell variant="head">Email Address</SubTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </MainTableCell>
    </TableRow>
  );
}

function TerminateContactsTable({
  personsData,
  personsWithError,
  resetSearchFilter,
  values,
  onChange,
}: TerminateContactsTableProps) {
  const [searchValues, setSearchValues] = useState<Array<string>>([]);
  const [errorFilter, setErrorFilter] = useState<ErrorFilter>("NONE");
  const tableRef = useRef<VirtuosoHandle>(null);

  const filteredPersons = useMemo(() => {
    let filterContacts = [] as Person[];

    // filter contacts based on error filter
    if (errorFilter === "SHOW_ERROR_ONLY") {
      filterContacts = personsData.filter(({ person_id }) => {
        return personsWithError?.has(person_id);
      });
    } else {
      filterContacts = [...personsData];
    }

    // filter contacts based on search filter
    if (searchValues.length > 0) {
      filterContacts = filterContacts.filter(({ prdcr_first_name, prdcr_last_name, preferred_email, login_id }) => {
        return searchValues.every((searchTerm) => {
          if (
            `${prdcr_first_name} ${prdcr_last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
            `${prdcr_last_name} ${prdcr_first_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
            `${prdcr_last_name}, ${prdcr_first_name}`.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            return true;
          }

          if (login_id && login_id.toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
          }

          if (preferred_email && preferred_email.toLowerCase().includes(searchTerm.toLowerCase())) {
            return true;
          }

          return false;
        });
      });
    }

    return filterContacts;
  }, [searchValues, personsData, errorFilter]);

  function handleSearch(search: Array<string> | string) {
    // The callback function you provide in the onSearch prop will receive the current array
    // of search terms when a new search term is added or removed from the array.
    if (typeof search !== "string") {
      setSearchValues(search);
    }
  }

  function handleClickSelectError(filter: ErrorFilter) {
    setErrorFilter(filter);
  }

  const displayContactTypes = (contactTypeValdation: ContactTypeValidation[]) => {
    return contactTypeValdation.map((contactType) => {
      return (
        <RequiredContactTypes key={`${contactType.contact_type_id}`}>
          <SAText type="standard" text={`${contactType.contact_type_description}`} />
          {contactType.agency_codes.map((agency_name: string) => {
            return <SAText key={agency_name} type="standard" text={`${agency_name}`} />;
          })}
        </RequiredContactTypes>
      );
    });
  };

  useEffect(() => {
    // scroll TableVirtuoso using tabbing
    const terminateTableContainer = document.getElementById("TerminateTableContainer") as HTMLElement;
    function focusContact(event: any) {
      let nextIndex: number | null = null;
      const activeElement = document.activeElement;

      // checking if focus element is inside the table
      const index = activeElement?.getAttribute("data-item-index");

      if (index) {
        if (event.keyCode == 9 && !event.shiftKey) {
          nextIndex = +index + 1;
        } else if (event.shiftKey && event.keyCode == 9) {
          nextIndex = +index - 1;
        }
      }

      if (nextIndex !== null && tableRef.current) {
        tableRef.current.scrollIntoView({
          index: nextIndex,
          behavior: "auto",
          align: "center",
        });
      }
    }

    terminateTableContainer?.addEventListener("keydown", focusContact);
    return () => {
      terminateTableContainer?.removeEventListener("keydown", focusContact);
    };
  }, []);

  useEffect(() => {
    if (resetSearchFilter) {
      setSearchValues([]);
    }
  }, [resetSearchFilter]);

  useEffect(() => {
    if (personsWithError && personsWithError.size > 0) {
      setErrorFilter("SHOW_ALL");
    } else {
      setErrorFilter("NONE");
    }
  }, [personsWithError]);

  return (
    <>
      <SearchContainer>
        <Search>
          <SASearch
            values={searchValues}
            fullWidth
            id={`searchbar`}
            placeholder="Search by name, login id, or email address"
            variant="search-terms"
            onSearch={(search: Array<string> | string) => handleSearch(search)}
          />
        </Search>
        {errorFilter === "SHOW_ERROR_ONLY" && (
          <SelectErrorButton
            label="Show All"
            textTransform="uppercase"
            variant="link-medium"
            color="accentBlue"
            onClick={() => handleClickSelectError("SHOW_ALL")}
          />
        )}
        {errorFilter === "SHOW_ALL" && (
          <SelectErrorButton
            label="Show Errors only"
            textTransform="uppercase"
            variant="link-medium"
            color="accentBlue"
            onClick={() => handleClickSelectError("SHOW_ERROR_ONLY")}
          />
        )}
      </SearchContainer>
      <TerminateTableContainer id="TerminateTableContainer">
        <TableVirtuoso
          ref={tableRef}
          data={filteredPersons}
          components={ContactsTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={(index, person) => {
            const personWithError = personsWithError?.get(person.person_id);
            const isError = personWithError !== undefined;
            return (
              <>
                <CheckBoxTableCell>
                  <Checkbox
                    data-item-index={index}
                    error={isError}
                    id={`table_checkbox_person_${index}`}
                    type="checkbox"
                    label=""
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      if (e.currentTarget.checked) {
                        const newValues = [...values, e.currentTarget.value];
                        onChange(newValues);
                      } else {
                        const newValues = values.filter((value) => value !== e.currentTarget.value);
                        onChange([...newValues]);
                      }
                    }}
                    value={person.person_id}
                    defaultChecked={values.includes(person.person_id)}
                  />
                </CheckBoxTableCell>
                <MainTableCell colSpan={3}>
                  <Table size="small" style={{ tableLayout: "fixed" }}>
                    <TableBody>
                      <TableRow>
                        <SubTableCell $error={isError}>
                          <strong>{`${person.prdcr_last_name}, ${person.prdcr_first_name}`}</strong>
                        </SubTableCell>
                        <SubTableCell $error={isError}>{person.login_id}</SubTableCell>
                        <SubTableCell $error={isError}>{person.preferred_email}</SubTableCell>
                      </TableRow>
                      {personWithError && personWithError.statusCode === 400 && (
                        <TableRow>
                          <SubTableCell colSpan={3} $error>
                            <CardValidation>
                              {personWithError.reason === "contact types" && (
                                <>
                                  <SAText type="standard">
                                    At least 1 agency contact must have contact type:&nbsp;
                                    <strong>
                                      {personWithError.required_contact_types
                                        ?.map((contact) => contact.contact_type_description)
                                        .join(", ")}
                                    </strong>
                                    . Add this contact type(s) to another person before terminating.
                                  </SAText>
                                  {personWithError.required_contact_types &&
                                    displayContactTypes(personWithError.required_contact_types)}
                                </>
                              )}
                              {personWithError.reason === "permissions" && (
                                <>
                                  <SAText
                                    type="standard"
                                    text="You do not have permission to terminate. Contact Agency Operations for assistance."
                                  />
                                  <Email>
                                    <SAText type="standard" text="Email Address" />
                                    <span>
                                      <a
                                        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}
                                        href="mailto:Agency.Operations@stateauto.com">
                                        Agency.Operations@stateauto.com
                                      </a>
                                    </span>
                                  </Email>
                                </>
                              )}
                              {personWithError.reason === "pending" && (
                                <SAText
                                  type="standard"
                                  text="Contact is being updated by another user. Try again later."
                                />
                              )}
                            </CardValidation>
                          </SubTableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </MainTableCell>
              </>
            );
          }}
        />
      </TerminateTableContainer>
    </>
  );
}

export default TerminateContactsTable;
